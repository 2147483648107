import React from "react"

import * as contentContainerStyles from "./contentContainer.module.scss" 

class ContentContainer extends React.Component {
  render() {
    const { children, paddingTop, paddingMobile, paddingMobileTop } = this.props
    return (
        <div className={`${contentContainerStyles.container} ${paddingTop ? contentContainerStyles.top : ""}
                            ${paddingMobile ? contentContainerStyles.mobilePadding : ""} ${paddingMobileTop ? contentContainerStyles.mobilePaddingTop : ""}`}>
            {children}
        </div>
    )
  }
}

export default ContentContainer
