import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentContainer from "../components/contentContainer"


class Verhaltensregeln extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const node = data.allMarkdownRemark.edges[0].node

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={node.frontmatter.seoTitle || node.frontmatter.title} description={node.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer paddingTop paddingMobile paddingMobileTop>
              <h1>{node.frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{__html: node.html}}></div>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Verhaltensregeln

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/footercontent/verhaltensregeln.md/"}}) {
        edges {
          node {
            fields {
              slug
            }
            html
            fileAbsolutePath
            frontmatter {
              title
              date
              seoTitle
              seoDescription
            }
          }
        }
      }
  }
`
